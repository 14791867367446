import { useEffect, useRef, useState } from 'react';
import { shapes, dia, util } from 'jointjs/dist/joint'
import { COLOUR_ON, COLOUR_OFF } from '../../common';
import './wire.css'

export default function Wire() {

  const containerRef = useRef();
  const [on, setOn] = useState(false);
  const [gates, setGates] = useState();
  const [maxXY, setMaxXY] = useState({ x: window.innerWidth - 1, y: 0.5 * window.innerHeight })

  useEffect(() => {

    var namespace = shapes;

    var graph = new dia.Graph({}, { cellNamespace: namespace });

    var paper = new dia.Paper({
      el: containerRef.current,
      model: graph,
      width: maxXY.x,
      height: maxXY.y,
      gridSize: 1,
      cellViewNamespace: namespace,
      interactive: false
    });

    window.addEventListener('resize', () => {
      const maxX = window.innerWidth - 1;
      const maxY = window.innerHeight - 1;
      paper.setDimensions(maxX - 1, 0.5 * maxY)
      paper.transformToFitContent({ padding: 20 });
    })

    var _gates = {
      input: new shapes.logic.Input({
        position: { x: 0.20 * maxXY.x, y: 0.19 * maxXY.y },
        size: { width: 0.10 * maxXY.x, height: 0.20 * maxXY.y }
      }),
      output: new shapes.logic.Output({
        position: { x: 0.75 * maxXY.x, y: 0.19 * maxXY.y },
        size: { width: 0.10 * maxXY.x, height: 0.20 * maxXY.y }
      })
    };

    // listeners
    paper.on('element:pointerdown', function (cellView) {
      if (cellView.model.id === _gates.input.id) setOn(on => !on);
    });

    var wires = [
      {
        source: { id: _gates.input.id, port: 'out' },
        target: { id: _gates.output.id, port: 'in' },
      }
    ]

    // add gates and wires to the graph
    graph.addCells(util.toArray(_gates));
    util.forIn(wires, function (attributes) {
      graph.addCell(paper.getDefaultLink().set(attributes));
    });

    setGates(_gates)
  }, [maxXY])

  useEffect(() => {
    if (gates) {
      gates.input.attr('rect/fill', on ? COLOUR_ON : COLOUR_OFF);
      gates.output.attr('rect/fill', on ? COLOUR_ON : COLOUR_OFF);
    }
  }, [on, gates]);

  return (
    <>
      <h1 className="heading">Wire</h1>
      <h3 className="headingSubtitle">A wire transfers the input straight to the output, unmodified</h3>
      <div ref={containerRef}></div>
    </>
  );
}
