import './Arrows.css'

export default function Arrows({ activePageId, pages, setPageId }) {
  return <>
    <div className="arrows">
      <button
        class="arrow-button"
        disabled={activePageId == 0}
        onClick={() => (activePageId !== 0) && setPageId(activePageId - 1)}
      >Previous</button>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <button
        class="arrow-button"
        disabled={activePageId == pages.length - 1}
        onClick={() => (activePageId !== pages.length - 1) && setPageId(activePageId + 1)}
      >Next</button>
    </div>
  </>
}