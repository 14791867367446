import './App.css'
import ActivePage from './ActivePage'

function App() {
  return (
    <ActivePage />
  );
}

export default App;
