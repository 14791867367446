import { useState } from 'react';
import { Wire, Not } from './pages'
import Arrows from './Arrows';
import './App.css'

const PAGES = [
  Wire,
  Not
]

export default function ActivePage() {
  const [pageId, setPageId] = useState(0)
  const Page = PAGES[pageId]
  return (<>
      <Page pageId={pageId}/>
      <Arrows 
        activePageId={pageId} 
        pages={PAGES}
        setPageId={setPageId} 
        />
    </>
  )
}